import GreyCross from "assets/images/icons/grey-circle-cross.svg";
import { useBasket } from "hooks/useBasket";

import NumberOnlyInput from "components/atoms/NumberOnlyInput";

export default function DonationInput () {
  const { basket, setDonationAmount, setDonate } = useBasket();

  return <div className="flex flex-row items-center">
    <div className="mr-4">Amount (£)</div>
    <NumberOnlyInput
      value={(basket.donationAmount / 100) || ""}
      onChange={e => setDonationAmount(parseFloat(e.currentTarget.value) * 100)}
      className="w-20 border-2 rounded-lg p-2"
      data-test-id="donation-input"
      allowDecimal={true}
      maxDecimalPlaces={2}
    />
    <button
      className="ml-4"
      onClick={() => setDonate(false)}
    ><img src={GreyCross}/></button>
  </div>;
}