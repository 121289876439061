import { Charity } from "reduxStore/services/api-v2/charities";

import VersionSensitiveLink from "components/atoms/VersionSensitiveLink";

type Conditions = {
  warnings: {
    [key: string]: {
      condition: boolean,
      message: string | JSX.Element
    }
  },
  notifications: {
    [key: string]: {
      condition: boolean,
      message: string | JSX.Element
    }
  },
}

export default function CharityAdminBanners ({ charity } : { charity: Charity }) {
  const conditions: Conditions = {
    warnings: {
      noItems: {
        condition: !charity.is_retailer && (Object.values(charity.items).filter(({ tier }) => tier > 0).length === 0),
        message: <div>
          Your page won&apos;t go live until you enter at least one item (along with the item condition) -
          <VersionSensitiveLink to="/adminpanel"> Edit Profile </VersionSensitiveLink>
        </div>
      },
      noCategories: {
        condition: !charity.is_retailer && (charity.categories.length === 0),
        message: <div>
          Your page won&apos;t go live until you enter at least one category -
          <VersionSensitiveLink to="/adminpanel"> Edit Profile </VersionSensitiveLink>
        </div>
      },
      noService: {
        condition: !charity.is_retailer && !(charity.postal_service.enabled || charity.collection_service.enabled || charity.dropoff_service.enabled),
        message: <div>
          Your page won&apos;t go live until you enable at least one way for people to donate -
          <VersionSensitiveLink to="/adminpanel"> Edit Profile </VersionSensitiveLink>
        </div>
      },
      inactive: {
        condition: !charity.is_retailer && !charity.active,
        message: <div>
          Your page is currently set to inactive. Toggle it on to enable your page to go live -
          <VersionSensitiveLink to="/adminpanel"> Edit Profile </VersionSensitiveLink>
        </div>
      },
      suspended: {
        condition: !!charity.suspended,
        message: <div>
          <span>Your account has been suspended. If you belief that this has been done in error then please </span>
          <VersionSensitiveLink to="/contact"> contact us </VersionSensitiveLink>
          <span>to discuss further</span>
        </div>
      },
    },
    notifications: {
      unverified: {
        condition: !charity.verified,
        message: <div>
          <span>Thank you, your application has been submitted and you will be notified once we have approved your account. Please</span>
          <VersionSensitiveLink to="/contact"> contact us </VersionSensitiveLink>
          <span>if you have any questions</span>
        </div>
      }
    }
  };

  const notifications = Object.values(conditions.notifications).filter(({ condition }) => condition );
  const warnings = Object.values(conditions.warnings).filter(({ condition }) => condition );

  if (notifications.length === 0 && warnings.length === 0) return null;

  const banners = <div>
    {
      notifications.map(({ message }, i) => (
        <div key={i} className="flex flex-row items-center justify-center border-b-2 bg-dpLightGreen p-4">
          <div className="flex flex-col items-start w-full max-w-xl">
            <div>{message}</div>
          </div>
        </div>
      ))
    }
    {
      warnings.map(({ message }, i) => (
        <div key={i} className="flex flex-row items-center justify-center border-b-2 bg-red-300 p-4">
          <div className="flex flex-col items-start w-full max-w-xl">
            <div>{message}</div>
          </div>
        </div>
      ))
    }
  </div>;
  return <>
    <div className="z-20 hidden sm:block absolute top-0 left-0 right-0 shadow-md">
      {banners}
    </div>
    {/* Make space for the absolute positioned div at the top */}
    <div className="rounded-lg border-2 sm:opacity-0 mb-2 sm:mb-0">
      {banners}
    </div>
  </>;
};