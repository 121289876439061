import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fuse from "fuse.js";
import { useVersionSensitiveNavigate } from "hooks/useVersionSensitiveNavigate";
import { useGetActiveCategoriesQuery } from "reduxStore/services/api-v2/categories";
import { useGetActiveCharitiesQuery } from "reduxStore/services/api-v2/charities";
import { useGetActiveItemsQuery } from "reduxStore/services/api-v2/items";
import { setSearchSuggestionsOpen, setTypedItem } from "reduxStore/slices/nav-bar/actions";
import { selectSearchSuggestionsOpen, selectTypedItem } from "reduxStore/slices/nav-bar/selectors";

import LinkButton from "components/atoms/LinkButton";
import VersionSensitiveLink from "components/atoms/VersionSensitiveLink";
import GenericModal from "components/molecules/GenericModal";
import NoItemResultsBlurb from "components/molecules/NoItemResultsBlurb";
import OrgImage from "components/molecules/OrgImage";

const resultTypes = {
  item: "ITEM",
  category: "CATEGORY",
  charity: "CHARITY"
} as {
  [key: string]: "ITEM" | "CATEGORY" | "CHARITY"
};

type ResultType = (typeof resultTypes)[keyof typeof resultTypes]

type SearchItem = {
  name: string,
  id: string,
  type: ResultType
}

type CharityCardProps = { charityId: number; onClick: () => void; }
const CharityCard = ({ charityId, onClick }: CharityCardProps) => {
  const { data: activeCharities } = useGetActiveCharitiesQuery("");

  if (!activeCharities) {
    return null;
  }

  const charity = activeCharities.byId[charityId];
  return <div className="">
    <button onClick={onClick}>
      <VersionSensitiveLink to={`/ch/${charity.slug}`} className="text-xl flex flex-row items-center mr-4 hover:underline font-medium text-left">
        <div className="w-10 h-10 mr-4 my-2 shrink-0 overflow-hidden flex flex-col justify-center">
          <OrgImage charity={charity} width={40} fieldName="logo_w40"/>
        </div>
        {charity.name}
      </VersionSensitiveLink>
    </button>
  </div>;
};

type ItemCardProps = { itemName: string, onClick: () => void }
const ItemCard = ( { itemName, onClick }: ItemCardProps) => {
  return <div
    className="text-xl w-1/2 lg:w-48 pr-4 text-left mt-2 font-medium"
  >
    <LinkButton
      text={itemName}
      onClick={onClick}
      className="text-xl text-ellipsis overflow-hidden whitespace-nowrap w-full lg:w-48 pr-4 text-left hover:underline"
    />
  </div>;
};

const SearchSuggestions = ({ displayInline }: {displayInline?: boolean}) => {
  const dispatch = useDispatch();
  const navigate = useVersionSensitiveNavigate();

  const { data: activeItems } = useGetActiveItemsQuery("");
  const { data: activeCategories } = useGetActiveCategoriesQuery("");
  const { data: activeCharities } = useGetActiveCharitiesQuery("");
  const itemSearchFilter = useSelector(selectTypedItem);
  const setItemSearchFilter = (itemString: string) => dispatch(setTypedItem({ value: itemString, hideSuggestions: true }));
  const isOpen = useSelector(selectSearchSuggestionsOpen);
  const setIsOpen = (isOpen: boolean) => {
    dispatch(setSearchSuggestionsOpen(isOpen));
  };

  const doSearch = (clickedItemName: string) => {
    navigate(`/map?itemSearch=${clickedItemName}`);
  };

  const onDismiss = () => setIsOpen(false);

  const searchData = useMemo<SearchItem[]>(() => {
    const itemData: SearchItem[] = activeItems ? Object.values(activeItems.byId).map(item => ({ id: `ITEM_${item.id}`, name: item.name, type: resultTypes.item })) : [];
    const categoryData: SearchItem[] = activeCategories ? activeCategories.map(category => ({ id: `CATEGORY_${category}`, name: category, type: resultTypes.category })) : [];
    const charityData: SearchItem[] = activeCharities
      ? Object.values(activeCharities.byId).map(charity => ({ id: `CHARITY_${charity.id}`, name: charity.name, type: resultTypes.charity }))
      : [];
    return [ ...itemData, ...categoryData, ...charityData ];
  }, [ activeItems, activeCategories, activeCharities ]);

  const fuse = useMemo<Fuse<SearchItem>>(() => {
    return new Fuse(searchData, {
      keys: [ "name" ],
      includeScore: true,
      threshold: 0.3
    });
  }, [ searchData ]);

  const searchResults = useMemo<Fuse.FuseResult<SearchItem>[]>(() => {
    return fuse.search(itemSearchFilter);
  }, [ fuse, itemSearchFilter ]);


  const searchResultsItemElements = (searchResults && searchResults.length > 0)
    ? searchResults.filter(result => [ resultTypes.item, resultTypes.category ].includes(result.item.type)).slice(0, 8).map(result => {
      return <ItemCard
        key={result.item.id}
        onClick={() => {
          setItemSearchFilter(result.item.name);
          doSearch(encodeURIComponent(result.item.name));
        }}
        itemName={result.item.name}
      />;
    })
    : null;

  const searchResultsCharityElements = (searchResults && searchResults.length > 0)
    ? searchResults.filter(result => [ resultTypes.charity ].includes(result.item.type)).slice(0, 4).map(result => {
      const charityId = parseInt(result.item.id.split("CHARITY_")[1]);
      return <CharityCard
        key={result.item.id}
        charityId={charityId}
        onClick={() => setIsOpen(false)}
      />;
    })
    : null;

  const modalContent = (searchResults && searchResults.length > 0)
    ? (
      <div className="w-full max-w-screen-xl flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 text-left">
          {(searchResultsItemElements && searchResultsItemElements.length > 0) && (<h1 className="text-3xl">Results</h1>)}
          <div className="flex flex-col flex-wrap max-h-44">
            {searchResultsItemElements}
          </div>
        </div>
        <div className="w-full lg:w-1/2 text-left mt-4 lg:mt-0">
          {(searchResultsCharityElements && searchResultsCharityElements.length > 0) && (<h1 className="text-3xl">Charitable Groups</h1>)}
          <div className="flex flex-col">
            {searchResultsCharityElements}
          </div>
        </div>
      </div>
    )
    : <NoItemResultsBlurb itemName={itemSearchFilter} />;


  return (
    <GenericModal
      onDismiss={onDismiss}
      isOpen={isOpen}
      className={`px-8 lg:px-24 pt-4 pb-6 flex flex-col bg-white w-full shadow-md items-center ${displayInline ? "" : "absolute"}`}
    >
      {modalContent}
    </GenericModal>
  );
};

export default SearchSuggestions;