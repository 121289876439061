import { useState } from "react";
import { formatAddress } from "@utils/addresses";
import { CarrierOption, DHL_SURCHARGE_AMOUNT, inboundCarrierOptions, POST_PRICE, tierNames } from "@utils/carrierOptions";
import { formatDate } from "@utils/dates";
import { CARRIER_NAMES } from "@utils/globalConsts";
import { formatPrice } from "@utils/money";
import { useBasket } from "hooks/useBasket";
import { useGetPublicCharityByIdQuery } from "reduxStore/services/api-v2/charities";
import { useGetIsPostcodeRemoteQuery } from "reduxStore/services/api-v2/data";
import { InboundServiceName } from "reduxStore/services/api-v2/orders";

import GreyBox from "components/atoms/GreyBox";
import LinkButton from "components/atoms/LinkButton";
import Spinner from "components/atoms/Spinner";
import YellowBox from "components/atoms/YellowBox";
import DonationInput from "components/molecules/DonationInput";
import LabelQuantitySelector from "components/molecules/LabelQuantitySelector";
import OrgImage from "components/molecules/OrgImage";

const RemoteSurchargeBox = () => {
  const [ showInfo, setShowInfo ] = useState(false);
  return <YellowBox className="mt-2">
    {
      showInfo
        ? <div>
          <div>
            Our courier, DHL, charges more for deliveries or collections outside of their core coverage area (England, Wales, and southern Scotland).
          </div>
          <LinkButton onClick={() => setShowInfo(false)} text="Close" className="font-bold"/>
        </div>
        : <div>
          <div>
            + DHL remote area surcharge: {formatPrice(DHL_SURCHARGE_AMOUNT)}
          </div>
          <LinkButton onClick={() => setShowInfo(true)} text="What's this?" className="font-bold"/>
        </div>
    }
  </YellowBox>;
};


const CharitySection = ({ charityId, isDhl, service }: { charityId: number; isDhl: boolean; service: InboundServiceName;}) => {
  const { basket } = useBasket();
  const { data: charity } = useGetPublicCharityByIdQuery(charityId);

  const { data: isCollectionRemoteRes } = useGetIsPostcodeRemoteQuery(basket.collectionAddress.postcode, {
    skip: !basket.collectionAddress.postcode || !isDhl
  });

  const { data: isDeliveryRemoteRes } = useGetIsPostcodeRemoteQuery(charity?.address?.postcode || "", {
    skip: !charity?.address?.postcode || !isDhl
  });

  if (!charity) {
    return <Spinner className="h-20" />;
  }

  const charityLabels = basket.labels[service][charity.id];
  return <div key={charity.id} className="flex flex-row items-start mb-8">
    <OrgImage charity={charity} width={40} fieldName="logo_w40" className="mr-8 hidden xs:block"/>
    <div className="flex flex-col items-start">
      <b className="mb-2">{charity?.name}</b>
      {
        Object.keys(charityLabels).map(tier => {
          const qty = charityLabels[tier];
          const tierPrice = qty * inboundCarrierOptions[service][tier].price;
          return <div key={tier} className="mb-2 flex flex-row items-center">
            <LabelQuantitySelector charityId={charity.id} tier={tier} service={service} className="mr-4" />
            <div>x {tierNames[tier]} ({ formatPrice(tierPrice) })</div>
          </div>;
        })
      }
      { (isCollectionRemoteRes?.is_remote || isDeliveryRemoteRes?.is_remote) && <RemoteSurchargeBox /> }
    </div>
  </div>;
};


type CarrierLabelsContentProps = {
  service: InboundServiceName;
}
const CarrierLabelsContent = ({ service }: CarrierLabelsContentProps) => {
  const { basket } = useBasket();

  const isDhl = ([ "dhlInbound" ].includes(service));

  return <div>
    {
      Object.keys(basket.labels[service]).map(charityId => {
        return <CharitySection charityId={parseInt(charityId)} isDhl={isDhl} service={service} key={charityId} />;
      })
    }
  </div>;
};


export default function BasketContents () {
  const { basket, hasDhlLabels, hasHermesLabels, hasCollectplusLabels, total, isLoading } = useBasket();


  if (isLoading) {
    return <Spinner className="h-20" />;
  }

  const collectionInfo = basket.collectionAddress.postcode
    ? (
      <div className="mb-8">
        <div>
          Parcels will be collected from:
        </div>
        <div className="font-bold">
          {formatAddress(basket.collectionAddress)}
        </div>
        {
          basket.collectionDate
            ? (
              <div> on <b>{formatDate(basket.collectionDate, "D MMM")}</b></div>
            )
            : null
        }
      </div>
    )
    : null;

  const dropoffInfo = (basket.post && basket.postToAddress.postcode)
    ? (
      <div className="mb-8">
        <div>
            Labels will be posted to:
        </div>
        <div className="font-bold">
          {Object.values(basket.postToAddress).filter(line => line).join(", ")}
        </div>
      </div>
    )
    : <div className="mb-8">
      <div>
          Labels will be emailed { basket.email && <span>to <b>{basket.email}</b></span>}
      </div>
    </div>;

  return <div>
    <div>
      {
        hasDhlLabels && <GreyBox className="mt-8">
          <h3 className="font-bold text-lg mb-2">{CARRIER_NAMES.dhl} collections:</h3>
          {collectionInfo}
          <CarrierLabelsContent service="dhlInbound"/>
        </GreyBox>
      }
      {
        hasHermesLabels && <GreyBox className="mt-8">
          <h3 className="font-bold text-lg mb-2">{CARRIER_NAMES.hermes} labels:</h3>
          {dropoffInfo}
          <CarrierLabelsContent service="hermesStores"/>
        </GreyBox>
      }
      {
        hasCollectplusLabels && <GreyBox className="mt-8">
          <h3 className="font-bold text-lg mb-2">{CARRIER_NAMES.collectplus} labels:</h3>
          {dropoffInfo}
          <CarrierLabelsContent service="collectplus"/>
        </GreyBox>
      }
      {
        (basket.post && (hasHermesLabels || hasCollectplusLabels)) && <GreyBox className="mt-8">
          <h3 className="font-bold text-lg mb-2">2<sup>nd</sup> Class label postage</h3>
          {formatPrice(POST_PRICE)}
        </GreyBox>
      }
      {
        (basket.donate) && <GreyBox className="mt-8">
          <h3 className="font-bold text-lg mb-2">Donation to DropPoint</h3>
          <DonationInput />
        </GreyBox>
      }
      <div className="mt-8 font-bold">Total: {formatPrice(total)}</div>
    </div>
  </div>;
}