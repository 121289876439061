import { CarrierName, InboundServiceName, OutboundServiceName, ServiceName } from "reduxStore/services/api-v2/orders";

export const cpdims = [ 60, 50, 50 ]; // '60cm x 50cm x 50cm'
export const dhldims = [ 60, 50, 50 ]; // '60cm x 50cm x 50cm'
export const hermesdims = [ 66, 41, 38 ]; // '66cm x 41cm x 38cm'

export const POST_PRICE = 95;
export const DHL_SURCHARGE_AMOUNT = 800;

export const tierNames: {
  [key: string]: string
} = {
  "2": "2kg",
  "5": "5kg",
  "10": "10kg",
  "15": "15kg",
  "25": "25kg",
  "25_60-50-30": "Medium",
  "25_60-50-50": "Large",
  "25_80-80-80": "Extra Large"
};

export const serviceToCarrier: { [key in ServiceName]: CarrierName } = {
  dhlInbound: "dhl",
  dhlOutbound: "dhl",
  hermesStores: "hermes",
  collectplus: "collectplus",
};

export const status = {
  created: "created",
  cancelled: "cancelled"
};

export const comparisonPriceUpdateDate = "March 2023";

export type CarrierOption = {
  carrier: CarrierName;
  service: ServiceName;
  price: number;
  weight: number;
  comparisonPrice: number;
  dims?: number[];
  dimsDescription?: string;
}

export const inboundCarrierOptions: {
  [service in InboundServiceName]: {
    [weight: number | string]: CarrierOption
  }
} = {
  collectplus: {
    // 5: {
    //   carrier: "collectplus",
    //   service: "collectplus",
    //   dims: cpdims,
    //   price: 519,
    //   weight: 5000,
    //   comparisonPrice: 521
    // },
    // 10: {
    //   carrier: "collectplus",
    //   service: "collectplus",
    //   dims: cpdims,
    //   price: 649,
    //   weight: 10000,
    //   comparisonPrice: 732
    // }
  },
  dhlInbound: {
    "25_60-50-30": {
      carrier: "dhl",
      service: "dhlInbound",
      dims: [ 60, 50, 30 ],
      price: 989,
      weight: 25000,
      comparisonPrice: 1092
    },
    "25_60-50-50": {
      carrier: "dhl",
      service: "dhlInbound",
      dims: dhldims,
      price: 1089,
      weight: 25000,
      comparisonPrice: 1092
    },
    "25_80-80-80": {
      carrier: "dhl",
      service: "dhlInbound",
      dims: [ 80, 80, 80 ],
      price: 1889,
      weight: 25000,
      comparisonPrice: 3119
    }
  },
  hermesStores: {
    2: {
      carrier: "hermes",
      service: "hermesStores",
      price: 319,
      weight: 2000,
      dimsDescription: "Max Length + Girth 2.45m",
      comparisonPrice: 366
    },
    5: {
      carrier: "hermes",
      service: "hermesStores",
      price: 519,
      weight: 5000,
      dimsDescription: "Max Length + Girth 2.45m",
      comparisonPrice: 521
    },
    15: {
      carrier: "hermes",
      service: "hermesStores",
      price: 649,
      weight: 15000,
      dimsDescription: "Max Length + Girth 2.45m",
      comparisonPrice: 738
    }
  }
};


export const outboundCarrierOptions: {
  [service in OutboundServiceName]: {
    [weight: number | string]: CarrierOption
  }
} = {
  collectplus: {
    // 5: {
    //   carrier: "collectplus",
    //   service: "collectplus",
    //   dims: cpdims,
    //   price: 519,
    //   weight: 5000,
    //   comparisonPrice: 521
    // },
    // 10: {
    //   carrier: "collectplus",
    //   service: "collectplus",
    //   dims: cpdims,
    //   price: 649,
    //   weight: 10000,
    //   comparisonPrice: 732
    // }
  },
  dhlOutbound: {
    5: {
      carrier: "dhl",
      service: "dhlOutbound",
      dims: dhldims,
      price: 689,
      weight: 5000,
      comparisonPrice: 736
    },
    10: {
      carrier: "dhl",
      service: "dhlOutbound",
      dims: dhldims,
      price: 889,
      weight: 10000,
      comparisonPrice: 1092
    },
    25: {
      carrier: "dhl",
      service: "dhlOutbound",
      dims: dhldims,
      price: 989,
      weight: 25000,
      comparisonPrice: 1092
    }
  },
  hermesStores: {
    2: {
      carrier: "hermes",
      service: "hermesStores",
      price: 319,
      weight: 2000,
      dimsDescription: "Max Length + Girth 2.45m",
      comparisonPrice: 366
    },
    5: {
      carrier: "hermes",
      service: "hermesStores",
      price: 519,
      weight: 5000,
      dimsDescription: "Max Length + Girth 2.45m",
      comparisonPrice: 521
    },
    15: {
      carrier: "hermes",
      service: "hermesStores",
      price: 649,
      weight: 15000,
      dimsDescription: "Max Length + Girth 2.45m",
      comparisonPrice: 738
    }
  }
};
