export const V2_PAGES_REGEXES = [
  /^\/$/,
  /^\/map$/,
  /^\/map\?/,
  /^\/ourstory$/,
  /^\/how-to-use-droppoint$/,
  /^\/how-we-help$/,
  /^\/categories/,
  /^\/signup/,
  /^\/admin\/emails$/,
  /^\/admin\/orders$/,
  /^\/admin\/home$/,
  /^\/admin\/charity\/[0-9]+$/,
  /^\/adminpanel/,
  /^\/packingguide/,
  /^\/faq/,
  /^\/gdpr/,
  /^\/terms/,
  /^\/terms-campus/,
  /^\/in-the-news/,
  /^\/contact/,
  /^\/postage/,
  /^\/post-checkout/,
  /^\/login/,
  /^\/logout/,
  /^\/charity-admin\/address/,
  /^\/charity-admin\/home/,
  /^\/charity-admin\/orders/,
  /^\/charity-admin\/settings/,
  /^\/ch/,
  /^\/account\/reset-password/,
  /^\/account\/update-password/,
  /^\/checkout\/.+/,
  /^\/add-labels\/.+/,
  /^\/droppoint-finder/,
];