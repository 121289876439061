import { Navigate } from "react-router-dom";
import { useVersionSensitiveNavigate } from "hooks/useVersionSensitiveNavigate";
import { V2_PAGES_REGEXES } from "utils/versions";

type Props = { to: string, replace: boolean }
const VersionSensitiveNavigate = ({ to, replace }: Props) => {
  const navigate = useVersionSensitiveNavigate();
  for (const regex of V2_PAGES_REGEXES) {
    if (to.match(regex)) {
      return <Navigate to={to} replace={replace}/>;
    }
  }

  navigate(to);
  return null;
};

export default VersionSensitiveNavigate;