import { useGetCharityByOwnerIdQuery } from "reduxStore/services/api-v2/charities";

import useCurrentUser from "./useCurrentUser";

const useCurrentCharity = () => {
  const { isLoading: isLoadingUser, user } = useCurrentUser();
  const { data: charityData, isLoading: isLoadingCharity, isFetching: isFetchingCharity } = useGetCharityByOwnerIdQuery(user?.id as number, { skip: (!user?.id || user.isAdmin) });

  if (isLoadingUser || isLoadingCharity) {
    return {
      isLoading: true,
      charity: null
    };
  }

  if (!user) {
    return {
      isLoading: false,
      charity: null
    };
  }

  return {
    isLoading: false,
    isFetching: isFetchingCharity,
    charity: charityData
  };
};

export default useCurrentCharity;