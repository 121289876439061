import { useState } from "react";
import { formatAddress } from "@utils/addresses";
import { DHL_SURCHARGE_AMOUNT, outboundCarrierOptions, POST_PRICE, tierNames } from "@utils/carrierOptions";
import { formatDate } from "@utils/dates";
import { CARRIER_NAMES } from "@utils/globalConsts";
import { formatPrice } from "@utils/money";
import { useBasket } from "hooks/useBasket";
import useCurrentCharity from "hooks/useCurrentCharity";
import useCurrentUser from "hooks/useCurrentUser";
import { useGetIsPostcodeRemoteQuery } from "reduxStore/services/api-v2/data";
import { OutboundServiceName } from "reduxStore/services/api-v2/orders";

import GreyBox from "components/atoms/GreyBox";
import LinkButton from "components/atoms/LinkButton";
import Spinner from "components/atoms/Spinner";
import YellowBox from "components/atoms/YellowBox";
import DonationInput from "components/molecules/DonationInput";
import LabelQuantitySelector from "components/molecules/LabelQuantitySelector";
import OrgImage from "components/molecules/OrgImage";


const RemoteSurchargeBox = () => {
  const [ showInfo, setShowInfo ] = useState(false);
  return <YellowBox className="mt-2">
    {
      showInfo
        ? <div>
          <div>
            Our courier, DHL, charges more for deliveries or collections outside of their core coverage area (England, Wales, and southern Scotland).
          </div>
          <LinkButton onClick={() => setShowInfo(false)} text="Close" className="font-bold"/>
        </div>
        : <div>
          <div>
            + DHL remote area surcharge: {formatPrice(DHL_SURCHARGE_AMOUNT)}
          </div>
          <LinkButton onClick={() => setShowInfo(true)} text="What's this?" className="font-bold"/>
        </div>
    }
  </YellowBox>;
};


const RecipientSection = ({ recipientId, isDhl, service }: { recipientId: string; isDhl: boolean; service: OutboundServiceName;}) => {
  const { basket } = useBasket();
  const { charity } = useCurrentCharity();

  const { data: isCollectionRemoteRes } = useGetIsPostcodeRemoteQuery(charity?.address?.postcode || "", {
    skip: !charity?.address?.postcode || !isDhl
  });

  const { data: isDeliveryRemoteRes } = useGetIsPostcodeRemoteQuery(basket.recipientAddresses[recipientId]?.postcode || "", {
    skip: !basket.recipientAddresses[recipientId]?.postcode || !isDhl
  });

  if (!charity) {
    return <Spinner className="h-20" />;
  }

  const recipientLabels = basket.outboundLabels[service][recipientId].labels;
  const recipientAddress = basket.recipientAddresses[recipientId];

  return <div key={charity.id} className="flex flex-row items-start mb-8">
    <div className="flex flex-col items-start">
      <b className="mb-2">{recipientAddress ? formatAddress(recipientAddress) : "Address pending"}</b>
      {
        Object.keys(recipientLabels).map(tier => {
          if (tier === "address") {
            return null;
          }
          const qty = recipientLabels[tier];
          const tierPrice = qty * outboundCarrierOptions[service][tier].price;
          return <div key={tier} className="mb-2 flex flex-row items-center">
            <LabelQuantitySelector charityId={charity.id} recipientId={recipientId} tier={tier} service={service} className="mr-4" />
            <div>x {tierNames[tier]} ({ formatPrice(tierPrice) })</div>
          </div>;
        })
      }
      { (isCollectionRemoteRes?.is_remote || isDeliveryRemoteRes?.is_remote) && <RemoteSurchargeBox /> }
    </div>
  </div>;
};


type CarrierLabelsContentProps = {
  service: OutboundServiceName;
}
const CarrierLabelsContent = ({ service }: CarrierLabelsContentProps) => {
  const { basket } = useBasket();

  const isDhl = ([ "dhlOutbound" ].includes(service));

  return <div>
    {
      Object.keys(basket.outboundLabels[service]).map(recipientId => {
        return <RecipientSection recipientId={recipientId} isDhl={isDhl} service={service} key={recipientId} />;
      })
    }
  </div>;
};


export default function BasketOutboundContents () {
  const { basket, hasOutboundDhlLabels, hasOutboundHermesLabels, hasOutboundCollectplusLabels, totalOutbound, isLoading } = useBasket();
  const { charity } = useCurrentCharity();
  const { user, isLoading: isLoadingUser } = useCurrentUser();


  if (isLoading || isLoadingUser || !charity || !user) {
    return <Spinner className="h-20" />;
  }

  const collectionInfo = charity?.address?.postcode
    ? (
      <div className="mb-8">
        <div>
          Parcels will be collected from:
        </div>
        <div className="font-bold">
          {formatAddress(charity.address)}
        </div>
        {
          basket.collectionDate
            ? (
              <div> on <b>{formatDate(basket.collectionDate, "D MMM")}</b></div>
            )
            : null
        }
      </div>
    )
    : null;

  const dropoffInfo = (basket.post && basket.postToAddress.postcode)
    ? (
      <div className="mb-8">
        <div>
            Labels will be posted to:
        </div>
        <div className="font-bold">
          {Object.values(basket.postToAddress).filter(line => line).join(", ")}
        </div>
      </div>
    )
    : <div className="mb-8">
      <div>
          Labels will be emailed { user.email && <span>to <b>{user.email}</b></span>}
      </div>
    </div>;

  return <div>
    <div>
      {
        hasOutboundDhlLabels && <GreyBox className="mt-8">
          <h3 className="font-bold text-lg mb-2">{CARRIER_NAMES.dhl} collections:</h3>
          {collectionInfo}
          <CarrierLabelsContent service="dhlOutbound"/>
        </GreyBox>
      }
      {
        hasOutboundHermesLabels && <GreyBox className="mt-8">
          <h3 className="font-bold text-lg mb-2">{CARRIER_NAMES.hermes} labels:</h3>
          {dropoffInfo}
          <CarrierLabelsContent service="hermesStores"/>
        </GreyBox>
      }
      {
        hasOutboundCollectplusLabels && <GreyBox className="mt-8">
          <h3 className="font-bold text-lg mb-2">{CARRIER_NAMES.collectplus} labels:</h3>
          {dropoffInfo}
          <CarrierLabelsContent service="collectplus"/>
        </GreyBox>
      }
      {
        (basket.post && (hasOutboundHermesLabels || hasOutboundCollectplusLabels)) && <GreyBox className="mt-8">
          <h3 className="font-bold text-lg mb-2">2<sup>nd</sup> Class label postage</h3>
          {formatPrice(POST_PRICE)}
        </GreyBox>
      }
      {
        (basket.donate) && <GreyBox className="mt-8">
          <h3 className="font-bold text-lg mb-2">Donation to DropPoint</h3>
          <DonationInput />
        </GreyBox>
      }
      <div className="mt-8 font-bold">Total: {formatPrice(totalOutbound)}</div>
    </div>
  </div>;
}