import { NavigateOptions,useNavigate } from "react-router-dom";
import { V2_PAGES_REGEXES } from "utils/versions";

export const useVersionSensitiveNavigate = () => {
  const navigate = useNavigate();

  return (to: string, options?: NavigateOptions) => {
    for (const regex of V2_PAGES_REGEXES) {
      if (to.match(regex)) {
        return navigate(to, options);
      }
    }
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}${to}`;
  };
};