import GreenTick from "assets/images/icons/green-tick.svg";

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label?: string;
  checked?: boolean;
  id?: string;
  containerClassName?: string;
}

export default function FancyCheckbox({ label, checked, containerClassName, className, id, ...otherProps }: Props) {
  return (
    <div className={containerClassName || ""}>
      <label className="flex flex-row items-center w-fit cursor-pointer select-none">
        <input
          type="checkbox"
          checked={checked}
          className={`appearance-none peer ${className || ""}`}
          id={id || ""}
          {...otherProps}
        />
        <div
          aria-hidden="true"
          className="mr-2 peer-focus-visible:border-2 shrink-0"
        >
          {
            checked
              ? <img src={GreenTick} className="w-8 h-8" id={id ? `${id}-box` : ""}></img>
              : <div
                className="w-8 h-8 border-2 border-dpGreen rounded-full box-border"
                aria-hidden="true"
                id={id ? `${id}-box` : ""}
              />
          }
        </div>
        {label && <div dangerouslySetInnerHTML={{ __html: label }}></div>}
      </label>
    </div>
  );
}
