import { useEffect } from "react";
import { useCookies } from "react-cookie";
import AutoComplete from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const LocationCookieAutoComplete = ({ className } : { className?: string }) => {
  const [ cookies, setCookie ] = useCookies([ "location" ]);
  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });

  useEffect(() => {
    if (placePredictions && placePredictions.length > 0) {
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        place => {
          if (place?.geometry?.location) {
            const location = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
              name: place.formatted_address
            };
            setCookie("location", JSON.stringify(location), {
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
              path: "/"
            });
          }
        }
      );
    }
  }, [ placePredictions ]);

  return <AutoComplete
    apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY as string}
    options={{
      types: [], // Use all place types - otherwise defaults to just cities
      componentRestrictions: {
        country: "gb"
      }
    }}
    onPlaceSelected={place => {
      if (place?.geometry?.location) {
        const location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          name: place.formatted_address
        };
        setCookie("location", JSON.stringify(location), {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          path: "/"
        });
      }
    }}
    className={className || ""}
    defaultValue={cookies?.location?.name || ""}
    onKeyDown={e => {
      if (e.key === "Enter") {
        getPlacePredictions({ input: e.currentTarget.value });
      }
    }}
  />;
};

export default LocationCookieAutoComplete;