import React, { useEffect, useRef } from "react";
import { useKeyDownEvents } from "hooks/useKeyDownEvents";
import { useOutsideClickEvents } from "hooks/useOutsideClickEvents";

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
  {
    onDismiss: () => void,
    isOpen: boolean,
    withBackdrop?: boolean,
    backdropClassName?: string,
    className?: string
  }

const GenericModal = ({ onDismiss, isOpen, withBackdrop, className, backdropClassName, ...props }: Props) => {
  const mdlRf = useRef(null);
  useOutsideClickEvents(
    [ mdlRf ],
    () => {
      onDismiss();
    },
    []
  );

  useKeyDownEvents(
    [ 27 ],
    () => {
      onDismiss();
    },
    []
  );

  if (!isOpen) {
    return null;
  }

  const modal = <div ref={mdlRf} {...props} className={`${className} z-50`}>
    {props.children}
  </div>;

  if (withBackdrop) {
    return <div className={`${backdropClassName} fixed left-0 right-0 top-0 h-screen w-screen bg-white bg-opacity-70 z-50`}>
      {modal}
    </div>;
  }

  return modal;
};

export default GenericModal;