import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { DHL_SURCHARGE_AMOUNT, inboundCarrierOptions, outboundCarrierOptions, POST_PRICE } from "@utils/carrierOptions";
import randomstring from "randomstring";
import charitiesApi, { Charity } from "reduxStore/services/api-v2/charities";
import dataApi from "reduxStore/services/api-v2/data";
import { useGetIsPostcodeRemoteQuery } from "reduxStore/services/api-v2/data";
import { InboundServiceName, OutboundServiceName, ServiceName } from "reduxStore/services/api-v2/orders";
import { EntireState } from "reduxStore/types";
import { AddressOptionalLatLng, AddressWithName } from "types/addresses";
import { Basket, ContactDetails, DEFAULT_ADDRESS, DEFAULT_BASKET, INBOUND_SERVICE_NAMES, OUTBOUND_SERVICE_NAMES, RecipientName } from "types/basket";

import useCurrentCharity from "./useCurrentCharity";

export const useGetPostcodeRemoteResults = (postcodes: string[]) => {
  const dispatch = useDispatch();

  const selectAllPostcodeResults = (state: EntireState) => {
    const postcodeResults: { [key: string]: boolean | undefined } = {};
    let isLoading = false;
    for (const postcode of postcodes) {
      if (postcode) {
        const { data, isLoading: isLoadingResult } = dataApi.endpoints.getIsPostcodeRemote.select(postcode)(state);
        postcodeResults[postcode] = data?.is_remote;
        isLoading = isLoading || isLoadingResult;
      } else {
        postcodeResults[postcode] = false;
      }
    }
    return { postcodeResults, isLoading };
  };

  const { postcodeResults, isLoading: isLoadingPostcodeResults } = useSelector((state: EntireState) => selectAllPostcodeResults(state));

  useEffect(() =>{
    for (const postcode of postcodes) {
      dispatch<any>(dataApi.endpoints.getIsPostcodeRemote.initiate(postcode));
    }
  }, [ JSON.stringify(Object.values(postcodes)) ]);

  return { postcodeResults, isLoading: isLoadingPostcodeResults };
};

export const useGetCharityPostcodeRemoteResults = (charityIds: number[]) => {
  const dispatch = useDispatch();

  const selectAllCharities = (state: EntireState, ids: number[]) => {
    const charities: { [key: number]: Charity | undefined } = {};
    let isLoading = false;
    for (const id of charityIds) {
      const { data, isLoading: isLoadingCharity } = charitiesApi.endpoints.getPublicCharityById.select(id)(state);
      charities[id] = data;
      isLoading = isLoading || isLoadingCharity;
    }
    return { charities, isLoading };
  };

  const { charities, isLoading: isLoadingCharities } = useSelector((state: EntireState) => selectAllCharities(state, charityIds));

  const { postcodeResults, isLoading: isLoadingPostcodeResults } = useGetPostcodeRemoteResults(Object.values(charities).map(ch => ch?.address?.postcode || ""));

  useEffect(() =>{
    for (const charityId of charityIds) {
      dispatch<any>(charitiesApi.endpoints.getPublicCharityById.initiate(charityId));
    }
  }, [ JSON.stringify(charityIds) ]);


  useEffect(() =>{
    for (const charity of Object.values(charities)) {
      if (charity?.address?.postcode) {
        dispatch<any>(dataApi.endpoints.getIsPostcodeRemote.initiate(charity.address.postcode));
      }
    }
  }, [ JSON.stringify(Object.values(charities).map(ch => ch?.address?.postcode)) ]);


  const charityPostcodeResults: { [key: number]: boolean | undefined } = {};
  for (const charity of Object.values(charities)) {
    if (charity) {
      charityPostcodeResults[charity.id] = postcodeResults[charity?.address?.postcode || ""];
    }
  }

  return { postcodeResults: charityPostcodeResults, isLoading: isLoadingCharities || isLoadingPostcodeResults };
};


const basketCorrectFormat = (basket: any) => {
  if (!(basket?.labels && basket?.labels?.hermesStores && basket?.labels?.collectplus && basket?.labels?.dhlInbound)) {
    return false;
  }
  if (!(basket?.outboundLabels && basket?.outboundLabels?.hermesStores && basket?.outboundLabels?.collectplus && basket?.outboundLabels?.dhlOutbound)) {
    return false;
  }
  if (!(basket?.outboundRecipientIds)) {
    return false;
  }
  if (!(basket?.recipientAddresses)) {
    return false;
  }
  if (!(basket?.recipientNames)) {
    return false;
  }
  if (!(basket?.recipientContactDetails)) {
    return false;
  }
  if (!(basket?.collectionAddress)) {
    return false;
  }
  if (!(basket?.collectionAddress)) {
    return false;
  }
  return true;
};

export const useBasket = () => {
  const [ cookies, setCookie ] = useCookies<"basket", { basket: Basket | null }>([ "basket" ]);

  const setBasketCookie = (val: Basket) => {
    setCookie("basket", val, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: "/"
    });
  };

  if (!basketCorrectFormat(cookies.basket)) {
    setBasketCookie(DEFAULT_BASKET);
  }

  const basket = cookies.basket || DEFAULT_BASKET;

  const { data: isRemoteCollectionAddressRes } = useGetIsPostcodeRemoteQuery(basket.collectionAddress.postcode, {
    skip: !basket.collectionAddress.postcode
  });

  const { charity } = useCurrentCharity();

  const { postcodeResults: postcodeRemoteResults, isLoading: isLoadingPostcodeResults } = useGetCharityPostcodeRemoteResults(Object.keys(basket.labels.dhlInbound).map( chId => parseInt(chId) ));
  const { postcodeResults: outboundCharityRemoteResults, isLoading: isLoadingOutboundPostcodeResults } = useGetCharityPostcodeRemoteResults(charity ? [ charity.id ] : []);

  const {
    postcodeResults: outboundRecipientPostcodeRemoteResults,
    isLoading: isLoadingOutboundRecipientPostcodeRemoteResults
  } = useGetPostcodeRemoteResults(Object.keys(basket.recipientAddresses).map(recipientId => basket.recipientAddresses[recipientId].postcode));

  const addLabel = (service: InboundServiceName, charityId: number, tier: string) => {
    const newNumLabels = basket.labels[service][charityId] && basket.labels[service][charityId][tier]
      ? basket.labels[service][charityId][tier] + 1
      : 1;

    setBasketCookie({
      ...basket,
      labels: {
        ...basket.labels,
        [service]: {
          ...basket.labels[service],
          [charityId]: {
            ...(basket.labels[service][charityId] || {}),
            [tier]: newNumLabels
          }
        }
      },
    });
  };

  const addOutboundLabel = (service: OutboundServiceName, recipientId: string, tier: string) => {
    const newNumLabels = basket.outboundLabels[service][recipientId] && basket.outboundLabels[service][recipientId].labels && basket.outboundLabels[service][recipientId].labels[tier]
      ? basket.outboundLabels[service][recipientId].labels[tier] + 1
      : 1;

    setBasketCookie({
      ...basket,
      outboundLabels: {
        ...basket.outboundLabels,
        [service]: {
          ...basket.outboundLabels[service],
          [recipientId]: {
            ...(basket.outboundLabels[service][recipientId] || {}),
            labels: {
              ...(basket.outboundLabels[service][recipientId]?.labels || {}),
              [tier]: newNumLabels
            }
          }
        }
      },
    });
  };

  const removeLabel = (service: InboundServiceName, charityId: number, tier: string) => {
    const newQty = basket.labels[service][charityId][tier] - 1;
    const newCharityLabels = {
      ...basket.labels[service][charityId],
      [tier]: basket.labels[service][charityId][tier] - 1
    };

    if (newQty === 0) {
      delete newCharityLabels[tier];
    }

    const newCarrierLabels = {
      ...basket.labels[service],
      [charityId]: newCharityLabels
    };

    if (Object.keys(newCharityLabels).length === 0) {
      delete newCarrierLabels[charityId];
    }

    setBasketCookie({
      ...basket,
      labels: {
        ...basket.labels,
        [service]: newCarrierLabels
      }
    });
  };

  const removeOutboundLabel = (service: OutboundServiceName, recipientId: string, tier: string) => {
    const newQty = basket.outboundLabels[service][recipientId].labels[tier] - 1;
    const newCharityLabels = {
      ...basket.outboundLabels[service][recipientId].labels,
      [tier]: basket.outboundLabels[service][recipientId].labels[tier] - 1
    };

    if (newQty === 0) {
      delete newCharityLabels[tier];
    }

    const newCarrierLabels = {
      ...basket.outboundLabels[service],
      [recipientId]: {
        ...basket.outboundLabels[service][recipientId],
        labels: newCharityLabels
      }
    };

    if (Object.keys(newCharityLabels).length === 0) {
      delete newCarrierLabels[recipientId];
    }

    setBasketCookie({
      ...basket,
      outboundLabels: {
        ...basket.outboundLabels,
        [service]: newCarrierLabels
      }
    });
  };

  const addRecipient = () => {
    const recipientId = randomstring.generate();
    setBasketCookie({
      ...basket,
      outboundRecipientIds: [ ...basket.outboundRecipientIds, recipientId ],
      recipientAddresses: {
        ...basket.recipientAddresses,
        [recipientId]: DEFAULT_ADDRESS
      }
    });
    return recipientId;
  };

  const deleteRecipient = (recipientId: string) => {
    const newRecipientIds = basket.outboundRecipientIds.filter(id => id !== recipientId);
    const newRecipientAddresses: { [key: string]: AddressOptionalLatLng } = {};
    for (const recipient of newRecipientIds) {
      newRecipientAddresses[recipient] = basket.recipientAddresses[recipient];
    }
    const newRecipientNames: { [key: string]: RecipientName } = {};
    for (const recipient of newRecipientIds) {
      newRecipientNames[recipient] = basket.recipientNames[recipient];
    }

    const newOutboundLabels = { ...DEFAULT_BASKET.outboundLabels };

    for (const carrier in basket.outboundLabels) {
      const carrierName = carrier as OutboundServiceName;
      for (const recipientId in basket.outboundLabels[carrierName]) {
        if (newRecipientIds.includes(recipientId)) {
          if (!newOutboundLabels[carrierName]) {
            newOutboundLabels[carrierName] = {};
          }
          newOutboundLabels[carrierName][recipientId] = basket.outboundLabels[carrierName][recipientId];
        }
      }
    }

    setBasketCookie({
      ...basket,
      outboundRecipientIds: basket.outboundRecipientIds.filter(id => id !== recipientId),
      recipientAddresses: newRecipientAddresses,
      recipientNames: newRecipientNames,
      outboundLabels: newOutboundLabels
    });
    return recipientId;
  };

  const recipientHasLabel = (recipientId: string) => {
    for (const service of OUTBOUND_SERVICE_NAMES) {
      if (basket.outboundLabels[service][recipientId] && basket.outboundLabels[service][recipientId].labels && Object.keys(basket.outboundLabels[service][recipientId].labels).length > 0) {
        return true;
      }
    }

    return false;
  };

  const setCollectionAddress = (addr: AddressOptionalLatLng) => {
    setBasketCookie({
      ...basket,
      collectionAddress: addr
    });
  };

  const setCollectionDate = (date: string) => {
    setBasketCookie({
      ...basket,
      collectionDate: date
    });
  };

  const setPackingGuideAccepted = (val: boolean) => {
    setBasketCookie({
      ...basket,
      packingGuideAccepted: val
    });
  };

  const setPost = (val: boolean) => {
    setBasketCookie({
      ...basket,
      post: val
    });
  };

  const setPostToAddress = (val: AddressWithName) => {
    setBasketCookie({
      ...basket,
      postToAddress: val
    });
  };

  const setDonate = (val: boolean) => {
    setBasketCookie({
      ...basket,
      donate: val
    });
  };

  const setDonationAmount = (val: number) => {
    setBasketCookie({
      ...basket,
      donationAmount: val
    });
  };

  const setEmail = (val: string) => {
    setBasketCookie({
      ...basket,
      email: val
    });
  };

  const setCollectionName = (val: string) => {
    setBasketCookie({
      ...basket,
      collectionName: val
    });
  };

  const setCollectionTelephone = (val: string) => {
    setBasketCookie({
      ...basket,
      collectionTelephone: val
    });
  };

  const setCollectionDetails = (address: AddressOptionalLatLng, name: string, phone: string) => {
    setBasketCookie({
      ...basket,
      collectionTelephone: phone,
      collectionName: name,
      collectionAddress: address,
    });
  };

  const setRecipientAddress = (recipientId: string, address: AddressOptionalLatLng) => {
    setBasketCookie({
      ...basket,
      recipientAddresses: {
        ...basket.recipientAddresses,
        [recipientId]: { ...address }
      }
    });
  };

  const setRecipientName = (recipientId: string, name: RecipientName) => {
    setBasketCookie({
      ...basket,
      recipientNames: {
        ...basket.recipientNames,
        [recipientId]: name
      }
    });
  };

  const setRecipientDetails = (recipientId: string, name: RecipientName, address: AddressOptionalLatLng, contactDetails: ContactDetails) => {
    setBasketCookie({
      ...basket,
      recipientAddresses: {
        ...basket.recipientAddresses,
        [recipientId]: { ...address }
      },
      recipientNames: {
        ...basket.recipientNames,
        [recipientId]: name
      },
      recipientContactDetails: {
        ...basket.recipientContactDetails,
        [recipientId]: contactDetails
      }
    });
  };

  const resetBasket = () => {
    setBasketCookie({
      ...basket,
      labels: { ...DEFAULT_BASKET.labels },
      donate: DEFAULT_BASKET.donate,
      donationAmount: DEFAULT_BASKET.donationAmount,
    });
  };

  const resetOutboundBasket = () => {
    setBasketCookie({
      ...basket,
      outboundLabels: { ...DEFAULT_BASKET.outboundLabels },
      outboundRecipientIds: [ ...DEFAULT_BASKET.outboundRecipientIds ],
      recipientAddresses: { ...DEFAULT_BASKET.recipientAddresses },
      recipientNames: { ...DEFAULT_BASKET.recipientNames },
      donate: DEFAULT_BASKET.donate,
      donationAmount: DEFAULT_BASKET.donationAmount,
    });
  };

  const hasDhlLabels = Object.keys(basket.labels.dhlInbound).length > 0;
  const hasHermesLabels = Object.keys(basket.labels.hermesStores).length > 0;
  const hasCollectplusLabels = Object.keys(basket.labels.collectplus).length > 0;

  const hasOutboundDhlLabels = Object.keys(basket.outboundLabels.dhlOutbound).length > 0;
  const hasOutboundHermesLabels = Object.keys(basket.outboundLabels.hermesStores).length > 0;
  const hasOutboundCollectplusLabels = Object.keys(basket.outboundLabels.collectplus).length > 0;


  let numLabels = 0;
  let total = 0;
  const labelCountsPerService: { [key in InboundServiceName]: number } = {
    dhlInbound: 0,
    hermesStores: 0,
    collectplus: 0,
  };
  for (const service of INBOUND_SERVICE_NAMES) {
    for (const charityId in basket.labels[service]) {
      for (const tier in basket.labels[service][charityId]) {
        const qty = basket.labels[service][charityId][tier];
        numLabels += qty;
        labelCountsPerService[service] += qty;
        total += qty * inboundCarrierOptions[service][tier].price;
      }

      if ([ "dhlInbound" ].includes(service)) {
        if (isRemoteCollectionAddressRes?.is_remote || postcodeRemoteResults[charityId]) {
          total += DHL_SURCHARGE_AMOUNT;
        }
      }
    }
  }

  if (basket.post && (hasCollectplusLabels || hasHermesLabels)) {
    total += POST_PRICE;
  }


  let numOutboundLabels = 0;
  let totalOutbound = 0;
  const outboundLabelCountsPerService: { [key in OutboundServiceName]: number } = {
    dhlOutbound: 0,
    hermesStores: 0,
    collectplus: 0,
  };
  for (const service of OUTBOUND_SERVICE_NAMES) {
    for (const recipientId in basket.outboundLabels[service]) {
      for (const tier in basket.outboundLabels[service][recipientId].labels) {
        const qty = basket.outboundLabels[service][recipientId].labels[tier];
        numOutboundLabels += qty;
        outboundLabelCountsPerService[service] += qty;
        totalOutbound += qty * outboundCarrierOptions[service][tier].price;
      }

      if ([ "dhlOutbound" ].includes(service)) {
        if (outboundRecipientPostcodeRemoteResults[basket.recipientAddresses[recipientId].postcode] || (charity && outboundCharityRemoteResults[charity.id])) {
          totalOutbound += DHL_SURCHARGE_AMOUNT;
        }
      }
    }
  }

  if (basket.donate) {
    total += basket.donationAmount;
    totalOutbound += basket.donationAmount;
  }


  if (basket.post && (hasOutboundCollectplusLabels || hasOutboundHermesLabels)) {
    totalOutbound += POST_PRICE;
  }

  return {
    basket,
    numLabels,
    numOutboundLabels,
    total,
    totalOutbound,
    isLoading: isLoadingPostcodeResults,
    labelCountsPerService,
    hasDhlLabels,
    hasHermesLabels,
    hasCollectplusLabels,
    hasOutboundDhlLabels,
    hasOutboundHermesLabels,
    hasOutboundCollectplusLabels,
    addLabel,
    addOutboundLabel,
    removeLabel,
    removeOutboundLabel,
    setCollectionAddress,
    setCollectionDate,
    setCollectionName,
    setCollectionTelephone,
    setCollectionDetails,
    setPackingGuideAccepted,
    setPost,
    setPostToAddress,
    setDonate,
    setDonationAmount,
    setEmail,
    setRecipientAddress,
    setRecipientName,
    setRecipientDetails,
    resetBasket,
    resetOutboundBasket,
    addRecipient,
    deleteRecipient,
    recipientHasLabel
  };
};