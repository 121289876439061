import { Selector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { Location } from "types/locations";

import { EntireState } from "../../types";

import { NavBarState } from "./types";

export const selectNavBarState = (
  state: EntireState
): NavBarState => state.navBar;

export const selectSearchedItem: Selector<EntireState, string> = createSelector(
  selectNavBarState,
  (navBar: NavBarState) => navBar.data.searchedItem
);

export const selectTypedItem: Selector<EntireState, string> = createSelector(
  selectNavBarState,
  (navBar: NavBarState) => navBar.data.typedItem
);

export const selectSearchSuggestionsOpen: Selector<EntireState, boolean> = createSelector(
  selectNavBarState,
  (navBar: NavBarState) => (navBar.ui.searchSuggestionsOpen && !!navBar.data.typedItem)
);

export const selectBurgerMenuOpen: Selector<EntireState, boolean> = createSelector(
  selectNavBarState,
  (navBar: NavBarState) => navBar.ui.burgerMenuOpen
);

export const selectFullMobileSearchBoxOpen: Selector<EntireState, boolean> = createSelector(
  selectNavBarState,
  (navBar: NavBarState) => navBar.ui.fullMobileSearchBoxOpen
);

export const selectSearchBoxHidden: Selector<EntireState, boolean> = createSelector(
  selectNavBarState,
  (navBar: NavBarState) => (navBar.ui.searchBoxHidden)
);