import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCreateItemAlertMutation } from "reduxStore/services/api-v2/items";
import { setItemAlertModalOpen } from "reduxStore/slices/item-alerts/actions";
import { selectItemAlertModalOpen } from "reduxStore/slices/item-alerts/selectors";

import BtnGreen from "components/atoms/BtnGreen";
import FadedUnderlinedInput from "components/atoms/FadedUnderlinedInput";
import FancyCheckbox from "components/atoms/FancyCheckbox";
import StandardModal from "components/molecules/StandardModal";

export default function ItemAlertModal() {
  const isOpen = useSelector(selectItemAlertModalOpen);
  const dispatch = useDispatch();
  const [ itemName, setItemName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ location, setLocation ] = useState("");
  const [ wouldDropOff, setWouldDropOff ] = useState(false);
  const [ wouldPost, setWouldPost ] = useState(false);
  const [ hasSubmitted, setHasSubmitted ] = useState(false);
  const [ createItemAlert, createItemAlertResult ] = useCreateItemAlertMutation();

  const resetState = () => {
    setLocation("");
    setEmail("");
    setItemName("");
    setWouldDropOff(false);
    setWouldPost(false);
    setHasSubmitted(false);
  };

  useEffect(() => {
    if (createItemAlertResult.status === "fulfilled") {
      return setHasSubmitted(true);
    }
    setHasSubmitted(false);
  }, [ createItemAlertResult.status ]);

  const modalContent = hasSubmitted
    ? <>
      <div className="text-3xl my-6">Thanks for submitting your request!</div>
      <div className="text-xl">We&apos;ve received an email and will let you know if we find someone to take your donations</div>
    </>
    : <>
      <div className="text-3xl mb-4">No Results?</div>
      <div>Let us know about the items you&apos;d like to donate and we&apos;ll get to work to find them a new home</div>
      <div className="flex flex-col">
        <FadedUnderlinedInput
          placeholder="I'd like to donate"
          onChange={e => setItemName(e.currentTarget.value)}
          value={itemName}
          className='mt-4 max-w-xs'
        />
        <FadedUnderlinedInput
          placeholder="Email Address"
          onChange={e => setEmail(e.currentTarget.value)}
          value={email}
          className='mt-4 max-w-xs'
        />
        <FadedUnderlinedInput
          placeholder="Location"
          onChange={e => setLocation(e.currentTarget.value)}
          value={location}
          className='mt-4 max-w-xs'
        />
        <FancyCheckbox
          label="I'd drop my items off locally"
          checked={wouldDropOff}
          onChange={e => {
            setWouldDropOff(e.currentTarget.checked);
          }}
          containerClassName="mt-4"
        />
        <FancyCheckbox
          label="I'd post my item donations"
          checked={wouldPost}
          onChange={e => {
            setWouldPost(e.currentTarget.checked);
          }}
          containerClassName="my-2"
        />
        <BtnGreen
          onClick={() => createItemAlert({
            item: itemName,
            location,
            email,
            dropoff: wouldDropOff,
            postal: wouldPost
          })}
          className="w-fit mt-4"
          disabled={!itemName || !location || !email}
        >
          <div><b>Find them a home</b></div>
        </BtnGreen>
      </div>
    </>;

  return <StandardModal
    onDismiss={() => {
      resetState();
      dispatch(setItemAlertModalOpen(false));
    }}
    isOpen={isOpen}
  >
    {modalContent}
  </StandardModal>;
}