import { InputProps } from "types/dom";


type DecimalControlInputProps = InputProps & {
  allowDecimal?: boolean;
  maxDecimalPlaces?: number;
};

export default function NumberOnlyInput ({ allowDecimal = false, maxDecimalPlaces = 2, ...otherProps }: DecimalControlInputProps) {
  const allowedKeys = [ "Backspace", "Enter", "Tab", ...(allowDecimal ? [ "." ] : []) ];

  const handleKeyDown = (event: { key: string; currentTarget: { value: string; }; preventDefault: () => void; }) => {
    if (!allowedKeys.includes(event.key) && !/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
    if (/[0-9]/.test(event.key) && event.currentTarget.value.split(".")[1].length >= maxDecimalPlaces) {
      event.preventDefault();
    }
  };

  return <input
    type="number"
    onKeyDown={handleKeyDown}
    {...otherProps}
  />;
}