import useCurrentCharity from "hooks/useCurrentCharity";
import useCurrentUser from "hooks/useCurrentUser";

import VersionSensitiveNavigate from "components/atoms/VersionSensitiveNavigate";
import FullPageSpinner from "components/molecules/FullPageSpinner";

const DefaultHomePage = () => {
  const { user, isLoading } = useCurrentUser();
  const { charity, isLoading: isLoadingCharity, isFetching: isFetchingCharity } = useCurrentCharity();

  if (isLoading || isLoadingCharity || isFetchingCharity) {
    return <FullPageSpinner/>;
  }


  if (user?.isAdmin) {
    return (
      <VersionSensitiveNavigate
        to="/admin/home"
        replace
      />
    );
  }

  if (charity && (user?.isCharityAdmin || user?.isRetailerAdmin)) {
    return (
      <VersionSensitiveNavigate
        to="/charity-admin/home"
        replace
      />
    );
  }

  return (
    <VersionSensitiveNavigate
      to="/"
      replace
    />
  );
};

export default DefaultHomePage;
